@import './classes.scss';

.App {
    font-family: 'Cheltenham', sans-serif;
    margin: 0 auto;
    padding: 0;
    width: 80%;
    max-width: $desktop-cutoff;

    .routes {
        width: 100%;
    }
    
    .Home {
        .headliner {
            width: fit-content;

            p {
                font-size: $base-text;
                line-height: 2;
            }
            div {
                img {
                    aspect-ratio: inherit;
                    height: $base-image;
                }
                .large-image {
                    aspect-ratio: inherit;
                    height: $large-image;
                }
                p {
                    color: $gray;
                    font-size: $small-text;
                    font-weight: 300;
                    margin: 0;
                } 
            }
        }
        
        .side-content {
            width: 100%;
            max-width: $side-content;

            .quote {
                max-width: $side-content;

                img {
                    aspect-ratio: inherit;
                    filter: brightness(0.8);
                    width: 100%;
                }
                p {
                    &:first-child {
                        font-size: $small-text;
                    }
                    &:last-child {
                        color: $gray;
                        font-size: $small-text;
                        font-weight: 300;
                        margin: auto 0;
                    }
                }
                
            }
            div {
                h3 {
                    font-size: $large-text;
                    align-self: flex-start;
                } 
                .article {
                    cursor: pointer;
                    max-width: $side-content;
    
                    &:hover {
                        filter: contrast(.75);
                        transition: filter 0.1s ease;
                    }
                    p {
                        &:first-child {
                            font-size: $small-text;
                            font-weight: 600;
                        }
                        &:last-child {
                            color: $gray;
                            font-size: $sub-text;
                            font-weight: 300;
                        }
                    }
                    img {
                        aspect-ratio: inherit;
                        height: $sub-image;
                        filter: brightness(0.8);
                    }
                }     

            }
        } 
    }

    .Projects {
        .project {
            cursor: pointer;
            width: $base-image;

            img {
                aspect-ratio: inherit;
                height: $small-image;
            }
            p, h3 {
                align-self: flex-start;
                margin: 0;
            }
            p {
                font-size: $small-text;
                font-weight: 300;
                color: $gray;
            }
            h3 {
                font-size: $base-text;
                font-weight: 600;
            }
        }
    }

    .ProjectFocus {
        div {
            img {
                aspect-ratio: inherit;
                width: 100%;
            }
            p {
                font-size: $base-text;
                line-height: 2;
            }
        }
    }

    .Contact {
        form {
            width: 50%;
        }
    }
}
