@import '../../classes.scss';

.Footer {
    margin: 0 auto;
    width: 100%;

    footer {
        > * {
            color: #000;
            font-size: .75rem;
            margin: $small-size 0;
        }
        a {
            color: #000;
            text-decoration: none;
        }  
    }
}

