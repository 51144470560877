@import '../../classes.scss';

.Navbar {
    margin: 0 auto;
    width: 100%;

    div {
        font-size: $small-text;
        font-weight: 300;
        margin: 0;
    }
    h1 {
        font-family: 'Chomsky', sans-serif;
        font-size: $large-size;
        font-weight: 300;
        margin: 0;
    }
    nav {
        > * {
            margin: $small-size 0;
        }
        a {
            color: #BFBFBF;
            text-decoration: none;
            span {color: #000;}
            &:hover {
                span {  
                    text-decoration: underline;
                    text-underline-offset: .1rem;
                    transition: .1s ease;
                }
            }
        }  
    }
}