$large-size: 3.5rem;
$medium-size: 1.5rem;
$small-size: 0.5rem;
$sub-size: 0.25rem;

$large-text: 1.5rem;
$base-text: 1rem;
$small-text: .75rem;
$sub-text: .5rem;

$large-image: 25rem;
$base-image: 15rem;
$small-image: 10rem;
$sub-image: 5rem;

$main-content: 50rem;
$side-content: 25rem;

$mobile-cutoff: 768px;
$tablet-cutoff: 1024px;
$desktop-cutoff: 1280px;

$gray: #BFBFBF;

:root {
    font-family: 'Cheltenham';
    font-size: 18px;
    margin: 0;
}

@font-face {
    font-family: 'Cheltenham Italic';
    src: url('./assets/fonts/cheltenham-italic-300.ttf') format('truetype');
}

@font-face {
    font-family: 'Cheltenham';
    src: url('./assets/fonts/cheltenham-normal-300.ttf') format('truetype');
}

@font-face {
    font-family: 'Chomsky';
    src: url('./assets/fonts/chomsky.otf') format('opentype');
}

.border {
    border: 1px solid #000;
}

.divider {
    align-self: stretch;
    border-left: 1px solid #000;
    height: auto;
    width: 0px;
}

.large-margin {
    margin: $large-size 0;
}

.medium-margin {
    margin: $medium-size 0;
}

.small-margin {
    margin: $small-size 0;
}

.sub-margin {
    margin: $sub-size 0;
}

.text-left {
    text-align: left !important;
}

.text-center {
    text-align: center !important;
}
                
.text-right {
    text-align: right !important;
}

@mixin flex-center {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
}

.flex-wrap-center {
    align-items: center !important;
    justify-content: center !important;
    flex-wrap: wrap;
}

.flex-wrap-start {
    align-items: center !important;
    justify-content: flex-start !important;
    flex-wrap: wrap;
}

.large-gap {gap: $large-size;}

.medium-gap {gap: $medium-size;}

.small-gap {gap: $small-size;}

.sub-gap {gap: $sub-size;}


.flex-body-row {
    @include flex-center;
    flex-direction: row;
    width: 100%;
}

.flex-body-column {
    @include flex-center;
    flex-direction: column;
    width: 100%;
}

.flex-parent-row {
    @include flex-center;
    flex-direction: row;
}

.flex-parent-column {
    @include flex-center;
    flex-direction: column;
}

.flex-child-row {
    @include flex-center;
    flex-direction: row;
}

.flex-child-column {
    @include flex-center;
    flex-direction: column;
}

.form {
    > * {
        font-family: 'Cheltenham';
        width: 100%;
    }
    input, textarea {
        border: 1px solid #000;
        border-radius: .15rem;
        box-sizing: border-box;
        padding: $small-size;
    }
}

.button {
    background-color: #000;
    border: none;
    border-radius: .15rem;
    color: #fff;
    padding: $small-size;
    &:hover {
        cursor: pointer;
        filter: contrast(.75);
        transition: filter 0.1s ease;
    }
}

.double-line {
    background-color: transparent;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    height: 2px;
    width: 100%;
}

.line {
    background-color: transparent;
    border-top: 1px solid #000;
    height: 2px;
    width: 100%;
}

.skill {
    background-color: #000;
    border-radius: $medium-size;
    color: #fff;
    font-size: $small-text;
    padding: $small-size;
    text-align: center;
}

@media(max-width: $tablet-cutoff) {
    :root {
        font-size: 16px;
    }

    $large-size: 3rem;
    $large-image: 20rem;

    .flex-body-row {flex-direction: column;}
    .flex-parent-row {flex-direction: column;}
}

@media(max-width: $mobile-cutoff) {
    :root {
        font-size: 14px;
    }

    $large-size: 2.5rem;

    .divider {display: none;}

    .Navbar {
        div {
            font-size: $small-size;
        }
    }

    .Home > div {
        width: 100%;
        div {
            img {
                aspect-ratio: inherit;
                max-height: 12.5rem;
                max-width: 15rem;
            }
            .large-image {
                aspect-ratio: inherit;
                max-height: 15rem;
                max-width: 17.5rem;
            }
        }
    }

    .Contact {
        form {
            width: 75% !important;
        }
    }
}